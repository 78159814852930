//* Basic
import $ from 'jquery';

//* Google Analitics

//* Components
import './modules/events';
import './modules/emailRecommendation';
import './modules/savedRecommendation';
import { modals } from './modules/modals';

//* Init modules
$(document).ready(() => {
  modals();
});
