import $ from 'jquery';

$(document).ready(() => {
    $('.menu-toggle').on('click', () => {
        console.log('menu');
        if ($('.menu-toggle').hasClass('menu-closed')) {
            $('.menu-toggle').removeClass('menu-closed');
            $('.menu-toggle').addClass('menu-open');
            $('.menu-links').css({'display': 'block'});
            $('html').css({'overflow': 'hidden'});
        } else {
            $('.menu-toggle').addClass('menu-closed');
            $('.menu-toggle').removeClass('menu-open');
            $('.menu-links').css({'display': ''});
            $('html').css({'overflow': ''});
        }
    });

    $('.tab-btn').on('click', (e) => {
        $('.tab-btn').removeClass('active');
        $(e.target).addClass('active');
        if ($('.login').hasClass('active')) {
            $('.login').css({
                'border-left': '1px solid #dbdbdb'
            });
            $('button#send').text('Zaloguj');
            $('.sign-up-inputs').addClass('hidden');
            $('.sign-in-inputs').removeClass('hidden');
            $('.sign-in-inputs').addClass('active-inputs');
            $('.sign-up-inputs').removeClass('active-inputs');
            $('.rules').addClass('hidden');
            $('.isDoctorBtn').addClass('hidden');
            $(".register-social").removeClass('hidden');

        } else {
            $('.login').css({
                'border-left': ''
            });
            $('button#send').text('Dołącz');
            $('.sign-in-inputs').addClass('hidden');
            $('.sign-up-inputs').removeClass('hidden');
            $('.sign-up-inputs').addClass('active-inputs');
            $('.sign-in-inputs').removeClass('active-inputs');
            $('.rules').removeClass('hidden');
            $('.isDoctorBtn').removeClass('hidden');

            if (!$(".isDoctor").hasClass('hidden')) {
                $(".register-social").addClass('hidden');
            }
        }
    });

    $('#send').on('click', (e) => {
        e.preventDefault();
        let validInputs = true;
        const loginAction = $('.login').hasClass('active');

        $('.active-inputs').find('input').each((index, item) => {
            if ($(item).is(':visible') && item.value === '') {
                $(item).addClass('error-input');
                validInputs = false;
            } else {
                $(item).removeClass('error-input');
            }
        });

        if (!$('#agreement').is(':checked')) {
            $('.rulesCheckbox').addClass('error-input');
            if (!loginAction) validInputs = false;
        } else {
            $('.rulesCheckbox').removeClass('error-input');
        }

        $(".isDoctorCheckbox").prop('checked', !$(".isDoctor").hasClass('hidden'));
        $('form').attr('action', loginAction ? '/login' : '/rejestracja');

        if (validInputs) $('form').submit();
    });

    $('.isDoctorBtn').on('click', function (e) {
        e.preventDefault();

        if ($(".isDoctor").hasClass('hidden')) {
            $(".isDoctor").removeClass('hidden');
            $(".register-social").addClass('hidden');
            $(this).text('Jestem pacjentem');
        } else {
            $(".isDoctor").addClass('hidden');
            $(".register-social").removeClass('hidden');
            $(this).text('Jestem lekarzem');
        }

    });

    $('.btn-print').click(function () {
        window.print();
    })
});