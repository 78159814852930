import $ from 'jquery';

$(document).ready(() => {
    $('#sendEmail').on('click', function (e) {
        e.preventDefault();

        const data = {
            'recommendation': $(this).data('recommendation'),
            'email': $('#emailRecipient').val(),
        };

        sendAjax('/api/sendEmail', data)
            .done(function (data) {
                $('.send-email-message')
                    .addClass('show')
                    .fadeOut(3000, function () {
                        $(this).removeClass('show');
                        $(this).css("display","");
                    });
            }).fail(function (data) {
            if ('errors' in data.responseJSON && 'email' in data.responseJSON.errors) {
                alert("Niepoprawny email, spróþuj jeszcze raz.");
            } else {
                alert("Coś poszło nie tak. Odśwież stronę i spróbuj jeszcze raz.");
            }
        });
    });

    function sendAjax(url, data) {
        return $.ajax({
            url: url,
            headers: {
                'csrf-token': $('meta[name=csrf-token]').attr("content")
            },
            method: 'POST',
            data: JSON.stringify(data),
        })
    }
});