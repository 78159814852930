import $ from 'jquery';

$(document).ready(() => {
    $('#save-recommendation').click(function (e) {
        e.preventDefault();

        let isSaved = $(this).hasClass('saved');
        const url = $(this).data('endpoint');
        const slug = $(this).data('recommendation');

        if(isSaved) {
            manageRecommendation(url, slug, 'remove')
                .done((data) => {
                    $(this).removeClass('saved');
                    $(this).find('span').text('Zapisz');
                }).fail(function (data) {
                    alert('Coś poszło nie tak');
            });
        } else {
            manageRecommendation(url, slug, 'add')
                .done((data) => {
                    $(this).addClass('saved');
                    $(this).find('span').text('Zapisano');
                }).fail(function (data) {
                    alert('Coś poszło nie tak');
            });
        }
    });

    $('.remove-recommendation').click(function (e) {
        e.preventDefault();
        const url = $('.saved-recommendations').data('endpoint');
        const slug = $(this).data('recommendation');

        manageRecommendation(url, slug, 'remove')
            .done((data) => {
                $(this).parent().remove();
            }).fail(function (data) {
                alert('Coś poszło nie tak');
        });
    });
});

function manageRecommendation(url, slug, action) {
    url = createManageUrl(url, slug, action);
    return $.ajax({
        'url': url,
        'method': 'POST',
    })
}

function createManageUrl(url, slug, action) {
    url = url.replace('_SLUG_', slug);
    url = url.replace('_ACTION_', action);

    return url;
}

export default function createEditLink(url, id) {
    return url.replace('_ID_', id);
}